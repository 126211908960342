export const routeNames = {
  authURl: "/auth",
  home: "/",
  login: "/login",
  signup: "/signup",
  verifyEmail: "/verify-email",
  unsupportedScreen: "/unsupported-screen",
  unsupportedBrowser: "/unsupported-browser",
  setupWorkspace: "/setup-workspace",
  setupWarehouse: "/setup-warehouse",
  validateUser: "/validate-user",
  provisioning: "/provisioning",
  dashboard: "/dashboard",
  ide: "/ide",
  ingestion: "/ingestion",
  chooseWarehouse: "/choose-warehouse",
  connectWarehouse: "/connect-warehouse",
  settings: "/settings",
  apiKeySettings: "/settings/api-key",
  resetPassword: "/reset-password",
  resetPasswordRequest: "/reset-password/request-sent",
  resetPasswordSuccess: "/reset-password/success",
  connector: "/ingestion/[connector-id]",
  team: "/team",
  joinWorkspace: "/signup/verify",
  profile: "/settings/profile",
  connections: "/settings/connections",
  addConnection: "/settings/connections/add",
  editConnection: "/settings/connections/edit",
  credentials: "/settings/credentials",
  editCredentials: "/settings/credentials/edit",
  addCredentials: "/settings/credentials/add",
  previewConnection: "/settings/connections/preview",
  testConnection: "/settings/connections/test",
  duplicateConnection: "/settings/connections/duplicate",
  billing: "/settings/billing",
  architecture: "/architecture",
  jobs: "/jobs",
  addJobs: "/jobs/add",
  addJobsData: "/jobs/data",
  bi: "/bi",
  environment: "/settings/environment",
  jobsRun: "/jobs/run",
  alerts: "/settings/alerts",
  dbtDocs: "/settings/docs",
  dbtDocsSideBar: "/docs",
  general: "/settings/general",
  dataApps: "/data-apps",
  security: "/settings/security",
  setupAuthentication: "/setup-authentication",
  authenticateCode: "/authenticate-code",
};
