export const emitterTypes = {
  NEW_CONNECTION: "NEW_CONNECTION",
  EDIT_CONNECTION: "EDIT_CONNECTION",
  UPDATE_CONNECTION: "UPDATE_CONNECTION",
  CREATE_CONNECTION: "CREATE_CONNECTION",
  DELETE_CONNECTION: "DELETE_CONNECTION",
  RESET_CONNECTION: "REST_CONNECTION",
  NEW_CREDENTIAL: "NEW_CREDENTIAL",
  EDIT_CREDENTIAL: "EDIT_CREDENTIAL",
  DELETE_CREDENTIAL: "DELETE_CREDENTIAL",
  RESET_CREDENTIAL: "REST_CREDENTIAL",
  CREATE_CREDENTIAL: "CREATE_CREDENTIAL",
  UPDATE_CREDENTIAL: "UPDATE_CREDENTIAL",
  SET_ERROR: "SET_ERROR",
  CREATE_API_KEY: "CREATE_API_KEY",
  SET_FIELD_ERROR: "SET_FIELD_ERROR",
  SHOW_EDIT_BUTTON: "SHOW_EDIT_BUTTON",
  OPEN_SIDE_DRAWER: "OPEN_SIDE_DRAWER",
  ADD_CONNECTOR: "ADD_CONNECTOR",
  DOWNLOAD_ARCHITECTURE_VIEW: "DOWNLOAD_ARCHITECTURE_VIEW",
  SAVE_CHANGES: "SAVE_CHANGES",
  CANCEL: "CANCEL",
  REFRESH_DATASOURCE: "REFRESH_DATASOURCE",
  SEARCH_DATASOURCE: "SEARCH_DATASOURCE",
  ADD_FILE: "ADD_FILE",
  ADD_FOLDER: "ADD_FOLDER",
  DELETE_FILE: "DELETE_FILE",
  DELETING_FILE: "DELETING_FILE",
  RELOAD_WORKSPACE: "RELOAD_WORKSPACE",
  FILE_FOLDER_CREATED: "FILE_FOLDER_CREATED",
  OPEN_FILE: "OPEN_FILE",
  PLACE_NAME_IN_EDITOR: "PLACE_NAME_IN_EDITOR",
  SNOWFLAKE_COLUMNS_LOADED: "SNOWFLAKE_COLUMNS_LOADED",
  DELETE_USER: "DELETE_USER",
  EDIT_USER: "EDIT_USER",
  DEACTIVATE_USER: "DEACTIVATE_USER",
  USER_DEACTIVATED: "USER_DEACTIVATED",
  USER_ACTIVATED: "USER_ACTIVATED",
  INVITE_USER: "INVITE_USER",
  ASSOCIATE_USER: "ASSOCIATE_USER",
  UPDATE_HEARTBEAT: "UPDATE_HEARTBEAT",
  CURRENT_QUERY: "CURRENT_QUERY",
  ALL_QUERIES: "ALL_QUERIES",
  RUN_QUERY: "RUN_QUERY",
  RESULTS_LOADING: "RESULTS_LOADING",
  RESULTS_DATA: "RESULTS_DATA",
  RESULTS_ERROR: "RESULTS_ERROR",
  CLEAR_RESULTS: "CLEAR_RESULTS",
  FORMAT_SQL: "FORMAT_SQL",
  CLOSE_RESULT_SECTION: "CLOSE_RESULT_SECTION",
  SET_QUERY_METADATA: "SET_QUERY_METADATA",
  LIMIT: "LIMIT",
  OPEN_METADATA: "OPEN_METADATA",
  RUN_BUTTON_DISABLED: "RUN_BUTTON_DISABLED",
  PREVIEW_DATA: "PREVIEW_DATA",
  STRING_FILTER: "STRING_FILTER",
  NUMERIC_FILTER: "NUMERIC_FILTER",
  DATE_FILTER: "DATE_FILTER",
  CLEAR_FILTER: "CLEAR_FILTER",
  DELETE_EXTERNAL_USER: "DELETE_EXTERNAL_USER",
  RESET_FILTERS: "RESET_FILTERS",
  ABORT_QUERY: "ABORT_QUERY",
  RESET_CREATE_ERROR: "RESET_CREATE_ERROR",
  SET_TEAMS_LOADER: "SET_TEAMS_LOADER",
  ADD_GIT_REPO: "ADD_GIT_REPO",
  COLLAPSE_RESULTS: "COLLAPSE_RESULTS",
  REFRESH_GIT_STATUS: "REFRESH_GIT_STATUS",
  FETCH_FILE_CONTENT: "FETCH_FILE_CONTENT",
  REFRESH_GIT: "REFRESH_GIT",
  RUN_NOW: "RUN_NOW",
  SAVE_JOB: "SAVE_JOB",
  NEW_JOB: "NEW_JOB",
  EDIT_JOB: "EDIT_JOB",
  PREVIEW_DATA_DBT: "PREVIEW_DATA_DBT",
  COMPILE_DATA_DBT: "COMPILE_DATA_DBT",
  BUILD_DATA_DBT: "BUILD_DATA_DBT",
  RESET_PREVIEW: "RESET_PREVIEW",
  RESET_BUILD: "RESET_BUILD",
  RESET_COMPILE: "RESET_COMPILE",
  RUN_COMMAND: "RUN_COMMAND",
  OPEN_TRIAL_MODAL: "OPEN_TRIAL_MODAL",
  CLOSE_TRIAL_MODAL: "CLOSE_TRIAL_MODAL",
  SELECTED_RESULT_TAB: "SELECTED_RESULT_TAB",
  TRIAL_UPGRADED: "TRIAL_UPGRADED",
  TRIAL_UPGRADE_SUCCESS: "TRIAL_UPGRADE_SUCCESS",
  EDIT_PROFILE: "EDIT_PROFILE",
  SET_HEADER_TITLE: "SET_HEADER_TITLE",
  OPEN_SUPERSET_PROVISIONING: "OPEN_SUPERSET_PROVISIONING",
  SET_WORKSHEET_LOADING: "SET_WORKSHEET_LOADING",
  RESEND_INVITE: "RESEND_INVITE",
  RENAME_JOB_MODAL: "RENAME_JOB_MODAL",
  UPDATE_JOBS: "UPDATE_JOBS",
  RESET_REVERT_LOADER: "RESET_REVERT_LOADER",
  START_LOADER: "START_LOADER",
  DBT_DOC_REPO: "DBT_DOC_REPO",
  MODIFY_LINEAGE_FILES: "MODIFY_LINEAGE_FILES",
  SHOW_DBT_DOC_NOTIFICATION: "SHOW_DBT_DOC_NOTIFICATION",
  PYTHON_QUERY: "PYTHON_QUERY",
  RUN_PYTHON_QUERY: "RUN_PYTHON_QUERY",
  UPDATE_PYTHON_PACKAGES: "UPDATE_PYTHON_PACKAGES",
  REFRESH_PYTHON_PACKAGES: "REFRESH_PYTHON_PACKAGES",
  REFRESH_PYTHON_PACKAGES_COMPLETED: "REFRESH_PYTHON_PACKAGES_COMPLETED",
  PYTHON_PACKAGE_INSTALLATION_ID: "PYTHON_PACKAGE_INSTALLATION_ID",
  PYTHON_TERMINAL: "PYTHON_TERMINAL",
  TRIGGER_PYTHON_TERMINAL: "TRIGGER_PYTHON_TERMINAL",
  PYTHON_STREAMING_QUERY: "PYTHON_STREAMING_QUERY",
  ABORT_PYTHON_QUERY: "ABORT_PYTHON_QUERY",
  RERUN_FAILED: "RERUN_FAILED",
  JOB_ABORTED: "JOB_ABORTED",
  SET_AUTH_CODE: "SET_AUTH_CODE",
  ABORT_PYTHON_LIBRARY_INSTALLATION: "ABORT_PYTHON_LIBRARY_INSTALLATION",
  CHANNEL_INTEGRATION: "CHANNEL_INTEGRATION",
  NEW_ALERT: "NEW_ALERT",
  OPEN_JUMPSTART_MODAL: "OPEN_JUMPSTART_MODAL",
  CLOSE_JUMPSTART_MODAL: "CLOSE_JUMPSTART_MODAL",
};
