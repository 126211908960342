import styled from "styled-components";
import theme from "../../styles/theme";
import NextImage from "@/uiCore/image";

export const HeaderWrapper = styled.div<{
  issettingsroute?: boolean;
  isJobsPage?: boolean;
  border: boolean;
}>`
  background: ${({ issettingsroute }) =>
    issettingsroute ? theme.neutral50 : theme.textWhite};
  border-bottom: ${({ issettingsroute, border, isJobsPage }) => {
    if (isJobsPage) return "none !important";
    else if (!issettingsroute) {
      if (border || border === undefined)
        return `1px solid ${theme.neutral200}`;
    } else {
      if (!border && border !== undefined) return "none !important";
    }
  }};
  padding: 20px 24px;
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
`;
export const PageWrapper = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
}));

export const ButtonWrapper = styled("div")(() => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: "16px",
}));

export const BackButtonWrapper = styled("div")(() => ({
  display: "flex",
  gap: "16px",
  alignItems: "center",
}));

export const TitleWrapper = styled("div")(() => ({
  display: "flex",
  gap: "16px",
  alignItems: "center",
}));

export const NewJobTitleWrapper = styled("div")<{ editMode: boolean }>(
  ({ editMode }) => ({
    background: editMode ? theme.neutral50 : theme.textWhite,
    height: "30px",
    display: "flex",
    alignItems: "center",
    gap: "4px",
    padding: "4px 8px",
  }),
);

export const NewJobEnv = styled("div")<{ isEditJobMode: any }>(
  ({ isEditJobMode }) => ({
    display: "flex",
    alignItems: "center",
    background: isEditJobMode ? theme.primary50 : theme.warning50,
    padding: "6px",
    height: "22px",
  }),
);

export const NextImageWrapper = styled(NextImage)<{
  isDisabled?: boolean;
}>(({ isDisabled }) => ({
  opacity: isDisabled ? 0.5 : 1,
}));

export const DateTypography = styled("div")(() => ({
  display: "flex",
  gap: "8px",
  alignItems: "center",
  borderRadius: "20px",
  background: theme.neutral100,
  padding: "6px 8px",
  margin: "0 8px",
}));
