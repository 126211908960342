/* eslint-disable complexity */
import React, { useEffect } from "react";
import {
  ButtonWrapper,
  HeaderWrapper,
  NewJobEnv,
  NewJobTitleWrapper,
  PageWrapper,
  TitleWrapper,
} from "./styled";
import Typography from "../../uiCore/typography";
import theme from "../../styles/theme";
import { useRouter } from "next/router";
import { ROUTES } from "../../constants/routes";
import { PrimaryButton } from "./helper";
import styles from "./index.module.css";
import NextImage from "@/uiCore/image";
import { commonIcons } from "@/constants/icons/common";
import { useSelector } from "react-redux";
import { routeNames } from "@/constants/routes/routeNames";
import AntSelect from "@/uiCore/select";
import { emitter } from "@/utils/emitter";
import { emitterTypes } from "@/utils/types";
import { Flex, Tooltip } from "antd";
import { DateTypography } from "./styled";

export const Header: React.FC<{
  isBackButton?: boolean;
  headerTitle?: string;
}> = ({ isBackButton, headerTitle }) => {
  const router = useRouter();
  const path = router.pathname;
  const urlParams = new URLSearchParams(window.location.search);
  const jobId = urlParams.get("id");
  const selectedJob = useSelector(
    (state: { jobs: any }) => state?.jobs?.selectedJob,
  )?.selectedJob;
  const editMode = useSelector(
    (state: { jobs: any }) => state?.jobs?.editMode,
  )?.editMode;
  let title = "";
  let isHeaderBorder;
  Object.keys(ROUTES).some((key) => {
    if (ROUTES[key].path === router.pathname) {
      title = ROUTES[key].name;
      isHeaderBorder = ROUTES[key].isHeaderBorder;
    }
  });
  const jobIdPathName = useSelector(
    (state: { jobs: any }) => state?.jobs?.backButtonRoute,
  )?.backButtonRoute;
  const pathName = window.location.pathname;

  const repoDropdownData = useSelector(
    (state: { dbtDocs: any }) => state?.dbtDocs?.repoData,
  )?.repoData;

  const [titleHeader, setTitleHeader] = React.useState<any>();
  const [selectedRepo, setSelectedRepo] = React.useState<any>(); //eslint-disable-line
  const [dataOptions, setDataOptions] = React.useState<any>([]);

  useEffect(() => {
    const isRunId = urlParams.get("runId");
    if (isRunId) {
      setTitleHeader(isRunId);
    } else {
      setTitleHeader(null);
    }
  }, [router.asPath]);

  useEffect(() => {
    updateDbtDocsUrls();
  }, [repoDropdownData]);

  const updateDbtDocsUrls = () => {
    const options = repoDropdownData?.map((item: any) => ({
      label: item.repositoryName,
      value: item.repositoryId,
    }));
    setDataOptions(options);
    if (options && options.length > 0) {
      const urlParams = new URLSearchParams(window.location.search);
      const repoId = urlParams.get("repoId");
      if (repoId) {
        emitter.emit(emitterTypes.DBT_DOC_REPO, repoId);
        setSelectedRepo(repoId);
      } else {
        emitter.emit(emitterTypes.DBT_DOC_REPO, options[0]?.value);
        setSelectedRepo(options[0]?.value);
      }
    }
  };

  const issettingsroute = path.includes("settings");
  const isEditJobMode = title === "New Job" && jobId;
  const isJobsPage = title === "New Job";

  const formatDate = (inputDate: any) => {
    const date = new Date(inputDate);

    const day = date.getUTCDate(); // Get the day
    const month = date.toLocaleString("en-GB", {
      month: "long",
      timeZone: "UTC",
    }); // Get full month name
    const year = date.getUTCFullYear(); // Get the year

    const hours = String(date.getUTCHours()).padStart(2, "0"); // Format hours to 2 digits
    const minutes = String(date.getUTCMinutes()).padStart(2, "0"); // Format minutes to 2 digits

    return `${day} ${month} ${year}, ${hours}:${minutes}`;
  };

  return (
    <HeaderWrapper
      border={isHeaderBorder === undefined ? true : isHeaderBorder}
      issettingsroute={issettingsroute}
      isJobsPage={isJobsPage}
    >
      <PageWrapper>
        {isBackButton ? (
          <div className={styles["header-back"]}>
            <NextImage
              src={commonIcons.backButton}
              height={24}
              width={24}
              alt={"back"}
              onClick={() => {
                const { asPath } = router;
                const routeSegments = asPath.split("/");
                const lastSegment = routeSegments[routeSegments.length - 1];
                const secondLastSegment =
                  routeSegments[routeSegments.length - 2];

                const routeStr =
                  routeSegments.length > 2 ? secondLastSegment : lastSegment;

                if (lastSegment.includes("run") && routeStr === "jobs") {
                  router.push(jobIdPathName);
                } else {
                  const matchedRoute = Object.keys(routeNames).find((key) =>
                    routeNames[key as keyof typeof routeNames].includes(
                      `/${routeStr}`,
                    ),
                  );
                  if (matchedRoute) {
                    router.push(
                      routeNames[matchedRoute as keyof typeof routeNames],
                    );
                  }
                }
              }}
              className="cursor-pointer"
            />
            <Typography.H3
              color={theme.textPrimary}
              weight={theme.fontWeightSemibold}
            >
              {isEditJobMode
                ? selectedJob?.workflowName
                : headerTitle
                  ? headerTitle
                  : title}
            </Typography.H3>
            {title === "New Job" ? (
              <NewJobTitleWrapper editMode={editMode}>
                <Typography.P3 color={theme.textTertiary}>
                  {isEditJobMode && !editMode
                    ? selectedJob?.environment.name
                    : ""}
                </Typography.P3>
                {isEditJobMode &&
                  !editMode &&
                  selectedJob?.environment.name && (
                    <NewJobEnv isEditJobMode={isEditJobMode}>
                      {
                        <Typography.P2 color={theme.primary700}>
                          PROD
                        </Typography.P2>
                      }
                    </NewJobEnv>
                  )}
              </NewJobTitleWrapper>
            ) : (
              titleHeader && (
                <TitleWrapper>
                  <Typography.H3
                    color={theme.textPrimary}
                    weight={theme.fontWeightSemibold}
                  >
                    {titleHeader ?? title}
                  </Typography.H3>
                </TitleWrapper>
              )
            )}
          </div>
        ) : (
          <TitleWrapper>
            <Typography.H3
              color={theme.textPrimary}
              weight={theme.fontWeightSemibold}
            >
              {titleHeader ?? title}
            </Typography.H3>
            {pathName === "/docs" && (
              <div className={styles["header-select"]}>
                <AntSelect
                  width="230px"
                  value={selectedRepo}
                  onChange={(value) => {
                    emitter.emit(emitterTypes.DBT_DOC_REPO, value.value);
                    setSelectedRepo(value.value);
                  }}
                  options={dataOptions}
                  labelInValue
                />
              </div>
            )}
          </TitleWrapper>
        )}
        <Flex>
          {selectedJob?.nextRun &&
            selectedJob?.status === "ACTIVE" &&
            ROUTES.AddJobsData.path === path && (
              <Tooltip title="Next run at" placement="left">
                <DateTypography>
                  <Flex>
                    <NextImage
                      src={commonIcons.nextRun}
                      height={16}
                      width={16}
                      alt="calender"
                    />
                    <Typography.P3 color={theme.textPrimary} margin="2px 4px">
                      {formatDate(selectedJob?.nextRun)}
                    </Typography.P3>
                  </Flex>
                </DateTypography>
              </Tooltip>
            )}
          <ButtonWrapper>
            <div>
              <PrimaryButton path={path} />
            </div>
          </ButtonWrapper>
        </Flex>
      </PageWrapper>
    </HeaderWrapper>
  );
};
