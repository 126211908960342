import { AlertsIcons } from "@/constants/icons/alerts";
import { ArchitectureIcons } from "@/constants/icons/architecture";
import { billingIcons } from "@/constants/icons/billing";
import { commonIcons } from "@/constants/icons/common";
import { dbtDocsIcons } from "@/constants/icons/dbtDocs";
import { IDEIcons } from "@/constants/icons/ide";
import { IngestionIcons } from "@/constants/icons/ingestion";
import { JobsIcons } from "@/constants/icons/jobs";
import { navigationIcons } from "@/constants/icons/navigations";
import { onboardingIcons } from "@/constants/icons/onboarding";
import { SettingsIcons } from "@/constants/icons/settings";
import { teamsIcons } from "@/constants/icons/teams";
import { trialIcons } from "@/constants/icons/trial";
import { utilizationIcons } from "@/constants/icons/utilization";
import { postFetcherHubspot } from "@/services/onboarding";
import React from "react";

export const removeObjectEmptyValues = (obj: object) => {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([value]) => value !== "" && value !== undefined && value !== null,
    ),
  );
};

export const removeDuplicatesByKey = (array: any[], key: string) => {
  const seen = new Set();
  return array.filter((item) => {
    const value = item[key];
    if (!seen.has(value)) {
      seen.add(value);
      return true;
    }
    return false;
  });
};

export function isEmpty(obj: object) {
  return Object.keys(obj).length === 0;
}

export const highlightText = (text: string, keyword?: string) => {
  if (!keyword) return text; // Return original text if no keyword provided
  const parts = text.split(new RegExp(`(${keyword})`, "gi"));
  return parts.map((part, index) =>
    part.toLowerCase() === keyword.toLowerCase() ? (
      <span key={index} className="highlight-text-tree-ide">
        {part}
      </span>
    ) : (
      part
    ),
  );
};

export const getDateFormated = () => {
  const now = new Date();
  const day = now.getDate().toString().padStart(2, "0");
  const month = (now.getMonth() + 1).toString().padStart(2, "0"); // January is 0!
  const year = now.getFullYear().toString().slice(-2); // Get last 2 digits of the year
  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const seconds = now.getSeconds().toString().padStart(2, "0");
  return `${day}-${month}-${year}-${hours}:${minutes}:${seconds}`;
};

export const convertToDatabaseFormat = (data: any[]) => {
  return data.map((db) => ({
    name: db.dbName,
    schemas: db.schemas.map((schema: any) => ({
      name: schema.schemaName,
      tables: schema.tables.map((table: any) => ({
        name: table.tableName,
        columns: table.columns.map((column: any) => column.name),
      })),
      views: schema.views.map((view: any) => ({
        name: view.viewName,
        columns: view.columns.map((column: any) => column.name),
      })),
    })),
  }));
};

export function sanitizeString(input: string) {
  return input?.replace(/[^a-zA-Z0-9]/g, "")?.toLowerCase() || "";
}

export const convertUTCtoLocalTime = (utcDate: string) => {
  const date = new Date(utcDate);
  const options = {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  };
  return date.toLocaleString("en-GB", options as any);
};

export const getTimeFromSeconds = (milliseconds: number) => {
  const seconds = Math.floor(milliseconds / 1000);
  const totalHours = Math.floor(seconds / 3600);
  const remainingMinutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  let timeString = "";

  if (totalHours > 0) {
    timeString += `${totalHours}h `;
  }
  if (remainingMinutes > 0) {
    timeString += `${remainingMinutes}m `;
  }
  if (remainingSeconds >= 0) {
    timeString += `${remainingSeconds}s`;
  }
  return timeString.trim();
};

export const getStepSize = (
  maxY: number,
): { maxY: number; stepSize: number } => {
  const defaultMaxY = maxY;
  let stepSize = 5;

  if (maxY <= 5) {
    maxY = 6;
  } else if (maxY < 10) {
    maxY = 10;
  } else if (maxY < 50) {
    maxY = Math.ceil(maxY / 5) * 5;
    if (maxY === defaultMaxY) maxY = maxY + 5;
  } else if (maxY < 100) {
    maxY = Math.ceil(maxY / 10) * 10;
    if (maxY === defaultMaxY) maxY = maxY + 10;
  } else {
    maxY = Math.ceil(maxY / 50) * 50;
    if (maxY === defaultMaxY) maxY = maxY + 50;
  }

  if (maxY % 3 === 0) {
    stepSize = 3;
  } else if (maxY % 4 === 0) {
    stepSize = 4;
  } else {
    stepSize = 5;
  }

  return { maxY, stepSize };
};

export const addCommaToNumber = (number: number | null | undefined): string => {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "";
};

export const preloadIcons = async (batchSize: number = 5) => {
  const icons = [
    AlertsIcons,
    commonIcons,
    ArchitectureIcons,
    billingIcons,
    dbtDocsIcons,
    IDEIcons,
    IngestionIcons,
    JobsIcons,
    navigationIcons,
    onboardingIcons,
    SettingsIcons,
    teamsIcons,
    trialIcons,
    utilizationIcons,
  ];
  for (let i = 0; i < icons.length; i += batchSize) {
    const batch = icons.slice(i, i + batchSize);
    await Promise.all(
      batch.map((icon) =>
        Promise.all(
          Object.keys(icon).map((key) => {
            const link = icon[key];
            const img = new Image();
            img.src = link;
            return new Promise((resolve) => {
              img.onload = resolve; // Resolve when the image is loaded
            });
          }),
        ),
      ),
    );
  }
};

export const triggerHubspotForm = (
  email: any,
  name: any,
  isInvitedUser: any,
) => {
  const portalId = process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID;
  const formId = process.env.NEXT_PUBLIC_HUBSPOT_FORM_ID;
  const apiKey = process.env.NEXT_PUBLIC_HUBSPOT_API_KEY;
  if (!portalId || !formId || !apiKey) return;
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;
  const firstname = name.split(" ")[0];
  const lastname = name.split(" ")[1] || "";
  const requestBody = {
    fields: [
      {
        objectTypeId: "0-1",
        name: "email",
        value: email,
      },
      {
        objectTypeId: "0-1",
        name: "firstname",
        value: firstname,
      },
      {
        objectTypeId: "0-1",
        name: "lastname",
        value: lastname,
      },
      {
        objectTypeId: "0-1",
        name: "signup_user",
        value: isInvitedUser ? "Invite signup" : "Self signup",
      },
      {
        objectTypeId: "0-1",
        name: "Self_serve_source",
        value: sessionStorage.getItem("utm_source") || "",
      },
      {
        objectTypeId: "0-1",
        name: "Self_serve_medium",
        value: sessionStorage.getItem("utm_medium") || "",
      },
      {
        objectTypeId: "0-1",
        name: "Self_serve_campaign",
        value: sessionStorage.getItem("utm_campaign") || "",
      },
    ],
  };
  if (
    document.cookie.replace(
      /(?:(?:^|.*;\s*)hubspotutk\s*=\s*([^;]*).*$)|^.*$/,
      "$1",
    )
  ) {
    const hutk = {
      hutk:
        document.cookie.replace(
          /(?:(?:^|.*;\s*)hubspotutk\s*=\s*([^;]*).*$)|^.*$/,
          "$1",
        ) || "",
    };
    Object.assign(requestBody, { context: hutk });
  }
  postFetcherHubspot(url, requestBody);
  const clearUTMSessionStorage = () => {
    sessionStorage.removeItem("utm_source");
    sessionStorage.removeItem("utm_medium");
    sessionStorage.removeItem("utm_campaign");
  };
  clearUTMSessionStorage();
};

export const heapIdentity = (
  name: string,
  email: string,
  workspace: string,
) => {
  const heap = (window as any).heap;
  heap.identify(email);
  setTimeout(() => heap.addUserProperties({ name, email, workspace }), 1000);
};

export function capitalizeFirstLetter(str: string | undefined) {
  if (str && typeof str === "string") {
    const cleanedStr = str.replace(/_/g, " ");
    return (
      cleanedStr.charAt(0).toUpperCase() + cleanedStr.slice(1).toLowerCase()
    );
  }
  return str;
}

export const getSecondsDifference = (time1: any, time2: any) => {
  // Helper function to convert time string to total seconds
  const timeToSeconds = (time: any) => {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  // Convert both times to seconds
  const seconds1 = timeToSeconds(time1);
  const seconds2 = timeToSeconds(time2);

  // Return the absolute difference between the two times
  return Math.abs(seconds1 - seconds2);
};

export const supportedFiles = () => {
  return [
    ".js",
    ".jsx",
    ".ts",
    ".tsx",
    ".py",
    ".java",
    ".c",
    ".cpp",
    ".h",
    ".cs",
    ".go",
    ".rb",
    ".php",
    ".rs",
    ".swift",
    ".kt",
    ".kts",
    ".dart",
    ".r",
    ".rmd",
    ".html",
    ".htm",
    ".css",
    ".scss",
    ".sass",
    ".less",
    ".md",
    ".markdown",
    ".xml",
    ".json",
    ".yaml",
    ".yml",
    ".toml",
    ".sql",
    ".csv",
    ".tsv",
    ".parquet",
    ".xml",
    ".sh",
    ".ps1",
    ".bat",
    ".cmd",
    ".ini",
    ".env",
    ".editorconfig",
    ".gitattributes",
    ".gitignore",
    "Makefile",
    "Dockerfile",
    ".dockerignore",
    ".tex",
    ".m",
    ".tf",
    ".tfvars",
    ".ipynb",
    ".svelte",
    ".vue",
    ".txt",
    ".text",
    ".pl",
    ".asm",
    ".vb",
    ".jsp",
    ".scala",
    ".groovy",
    ".properties",
    ".config",
    ".log",
    ".lua",
    ".erl",
    ".elixir",
    ".clj",
    ".cljs",
    ".edn",
  ];
};
