import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import NavbarHeader from "./navHeader";
import {
  NavigationsBottom,
  NavigationsMiddle,
  NavigationsTop,
} from "../../constants/navigation";
import Navigations from "./navigations";
import { NavbarWrapper } from "./styled";
import styles from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setNavigation } from "../../redux/userSlice";
import TrialComponent from "./trial";
import { emitter } from "../../utils/emitter";
import { emitterTypes } from "../../utils/types";

const Navbar: React.FC = () => {
  const dispatch = useDispatch();
  const handleHover = (value: boolean) => {
    dispatch(setNavigation({ navbarHovered: value }));
  };
  const [updatedNavigationsBottom, setUpdatedNavigationsBottom] =
    useState(NavigationsBottom);
  const [updatedNavigationsTop, setUpdatedNavigationsTop] =
    useState(NavigationsTop);

  const [updatedNavigationsMiddle, setUpdatedNavigationsMiddle] =
    useState(NavigationsMiddle);

  const companyDetails =
    useSelector((state: { user: any }) => state.user.companyDetails)
      ?.companyDetails || {};

  const jumpstartEnabled = companyDetails?.showJumpStartIcon;

  const showDocsOnNavbar = useSelector(
    (state: { user: any }) => state.user.showDocsOnNavbar,
  ).showDocsOnNavbar;

  const showDataAppsOnNavbar = useSelector(
    (state: { user: any }) => state.user.showDataAppsOnNavbar,
  ).showDataAppsOnNavbar;

  useEffect(() => {
    if (showDocsOnNavbar) {
      setUpdatedNavigationsMiddle((prev: any) => {
        // Remove any existing "Docs" item if present
        const filteredPrev = prev.filter((item: any) => item.id !== "dbtDocs");

        // Insert "Docs" at the second position
        return [
          filteredPrev[0], // First item (if it exists)
          {
            name: "Docs",
            icon: "dbtDocWhite",
            redirectTo: "/docs",
            enabled: true,
            id: "dbtDocs",
          },
          ...filteredPrev.slice(1), // Rest of the items after the first
        ];
      });
    } else {
      setUpdatedNavigationsMiddle(NavigationsMiddle);
    }
  }, [showDocsOnNavbar]);

  useEffect(() => {
    if (showDataAppsOnNavbar) {
      setUpdatedNavigationsTop((prev: any) => {
        return [
          ...prev,
          {
            name: "Data Apps",
            icon: "dataApps",
            redirectTo: "/data-apps",
            enabled: true,
            id: "data_apps",
          },
        ];
      });
    }
  }, [showDataAppsOnNavbar]);

  useEffect(() => {
    if (!jumpstartEnabled) {
      handleTrialUpgradeSuccess();
    }
  }, [jumpstartEnabled]);

  const handleTrialUpgradeSuccess = () => {
    setUpdatedNavigationsBottom((prev: any) => {
      return prev.filter((item: any) => item.id !== "jumpstart");
    });
  };

  useEffect(() => {
    emitter.on(emitterTypes.TRIAL_UPGRADE_SUCCESS, handleTrialUpgradeSuccess);
    return () => {
      emitter.off(
        emitterTypes.TRIAL_UPGRADE_SUCCESS,
        handleTrialUpgradeSuccess,
      );
    };
  }, []);

  return (
    <NavbarWrapper
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      <div>
        <NavbarHeader />
        <div className={styles.divider}>
          <Divider />
        </div>
        <Navigations navigationData={updatedNavigationsTop as any} />
        <div className={styles.divider}>
          <Divider />
        </div>
        <Navigations navigationData={updatedNavigationsMiddle as any} />
        <TrialComponent />
      </div>
      <div>
        <Navigations navigationData={updatedNavigationsBottom as any} />
      </div>
    </NavbarWrapper>
  );
};

export default Navbar;
