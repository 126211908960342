import { createSlice } from "@reduxjs/toolkit";

export const utilizationSlice = createSlice({
  name: "utilization",
  initialState: {
    utilizationData: {},
    usageData: [],
    timePeriodData: {
      startDate: "",
      endDate: "",
      tabValue: 0,
      customValue: "",
      payloadValue: "MONTH_TO_DATE",
      customTitle: "Daily",
    },
    cardLoading: true,
    categorys: [],
    aggregatedData: [],
    totalCost: 0,
    jobs: {},
  },

  reducers: {
    setUtilizationData: (state, action) => {
      state.utilizationData = action.payload;
    },
    setTimePeriod: (state, action) => {
      state.timePeriodData = { ...action.payload };
    },
    setCardLoading: (state, action) => {
      state.cardLoading = action.payload;
    },
    setCategorys: (state, action) => {
      state.categorys = action.payload;
    },
    setUsageData: (state, action) => {
      state.usageData = action.payload;
    },
    setAggregatedData: (state, action) => {
      state.aggregatedData = action.payload;
    },
    setTotalCost: (state, action) => {
      state.totalCost = action.payload;
    },
    setJobs: (state, action) => {
      state.jobs = action.payload;
    },
  },
});
export default utilizationSlice.reducer;

export const {
  setUtilizationData,
  setTimePeriod,
  setCardLoading,
  setCategorys,
  setUsageData,
  setAggregatedData,
  setTotalCost,
  setJobs,
} = utilizationSlice.actions;
