export const JobsIcons: { [key: string]: string } = {
  baseJobs: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/`,
  addNodes: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/addNodes.svg`,
  jobsPlaceholder: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/jobs-placeholder.svg`,
  nodes: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/nodes.svg`,
  lightening: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/lightning.svg`,
  deleteNode: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/delete-node.svg`,
  addEdgeLevel: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/add-edge-level.svg`,
  runnow: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/runnow.svg`,
  playTertiary: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/play_tertiary.svg`,
  warning: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/warning.svg`,
  runSuccess: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/runSuccess.svg`,
  runFail: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/runFail.svg`,
  running: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/running.svg`,
  noRun: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/noRun.svg`,
  successCircleFilled: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/success-circle-filled.svg`,
  errorCircleFilled: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/error-circle-filled.svg`,
  downloadLogs: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/download-logs.svg`,
  reRun: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/re-run.svg`,
  activeCaretDown: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/active-caret-down.svg`,
  skippedStatus: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/skipped-status.svg`,
  abortedStatus: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/aborted-status.svg`,
  omittedStatus: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/omitted-status.svg`,
  aborted: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/aborted.svg`,
  union: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/union.svg`,
  unionActive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/union-active.svg`,
  downstream: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/downstream.svg`,
  upstream: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/upstream.svg`,
  node: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/node.svg`,
  fullNode: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/full_node.svg`,
  queued: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/job-queue.svg`,
  noStatus: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/no-status.svg`,
  jobIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/jobIcon.svg`,
  accessToken: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}jobs/access-token.svg`,
};
