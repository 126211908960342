import { ROUTES } from "../routes";

export const mapRoutes: any = {
  IDE: ROUTES.IDE.path,
  INGESTION: ROUTES.Ingestion.path,
  JOBS: ROUTES.Jobs.path,
  BUSINESS_INTELLIGENCE: ROUTES.BI.path,
  ARCHITECTURE: ROUTES.Architecture.path,
  UTILIZATION: ROUTES.Utilization.path,
  TEAM: ROUTES.Team.path,
  SETUP_AUTHENTICATION: ROUTES.SetupAuthentication.path,
};
