import React, { ReactNode } from "react";
import styled from "styled-components";
import { Button } from "antd";
import {
  ButtonSizes,
  ButtonVariant,
  getBackgroundColor,
  getBorder,
  getBorderColor,
  getColor,
  getFocussedOutline,
  getHeight,
  getHoverBackgroundColor,
  getHoverBorderColor,
  getPadding,
} from "./helper";
import theme from "../../styles/theme";
import useKeypress from "@/hooks/useKeyPress";
import NextImage from "../image";

type ButtonProps = {
  variant: ButtonVariant;
  size: ButtonSizes;
  leftIcon?: string;
  rightIcon?: string;
  fullWidth?: boolean;
  inactive?: boolean;
  children: ReactNode;
  loading?: boolean;
  success?: boolean;
  margin?: string;
  height?: string;
  type?: "button" | "submit" | "reset";
  handleClick?: () => void;
};
const StyledButton = styled(Button)<ButtonProps>(
  // eslint-disable-next-line
  ({ variant, size, fullWidth, inactive, success, margin, height }) => ({
    fontSize: theme.fontP2,
    fontWeight: theme.fontWeightSemibold,
    background: `${getBackgroundColor(
      success ? "success" : variant,
      inactive === true,
    )} !important`,
    color: getColor(success ? "success" : variant, inactive === true),
    padding: getPadding(size),
    height: height ?? getHeight(size),
    borderRadius: theme.buttonBorderRadius,
    width: fullWidth ? "100%" : "fit-content",
    margin: margin ? margin : 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    boxShadow: "none !important",
    border: `${getBorder(success ? "success" : variant, inactive === true) || "none"}  ${
      getBorderColor(success ? "success" : variant, inactive === true) ||
      "transparent"
    }!important`,
    cursor: inactive ? "not-allowed" : "pointer",
    "&:hover": {
      background: !inactive
        ? `${getHoverBackgroundColor(success ? "success" : variant)} !important`
        : `${getBackgroundColor(success ? "success" : variant, inactive === true)} !important`,
      color: `${getColor(success ? "success" : variant, inactive === true)} !important`,
      borderColor: !inactive
        ? `${getHoverBorderColor(success ? "success" : variant)} !important`
        : theme.neutral200,
    },
    "&:focus": {
      outline: !inactive
        ? `3px solid ${getFocussedOutline(success ? "success" : variant)} !important`
        : "none",
      borderColor: !inactive
        ? `${getHoverBackgroundColor(success ? "success" : variant)} !important`
        : `${getBackgroundColor(success ? "success" : variant, inactive === true)} !important`,
    },
    "&:focus-visible": {
      outline: `2px solid ${theme.primary400} !important`,
    },
  }),
);

const AntButton: React.FC<ButtonProps> = ({
  leftIcon,
  rightIcon,
  children,
  loading,
  inactive,
  margin,
  type,
  handleClick,
  ...rest
}) => {
  const handleEnterKeyPress = () => {
    if (!inactive && type === "submit" && handleClick) {
      handleClick();
    }
  };

  // Using the useKeypress hook to detect Enter key press
  useKeypress("Enter", handleEnterKeyPress);
  return (
    <StyledButton
      {...rest}
      id={`${typeof children === "string" ? children.trim().replace(/\s+/g, "-") : children}`}
      loading={loading}
      inactive={inactive}
      onClick={!inactive ? handleClick : undefined}
      margin={margin}
    >
      {leftIcon && (
        <NextImage
          id={`${typeof children === "string" ? children.trim().replace(/\s+/g, "-") : children}`}
          src={leftIcon}
          alt={leftIcon}
          style={{ opacity: inactive ? 0.2 : 1 }} // eslint-disable-line
        />
      )}
      <span
        id={`${typeof children === "string" ? children.trim().replace(/\s+/g, "-") : children}`}
      >
        {children}
      </span>
      {rightIcon && <NextImage src={rightIcon} alt={rightIcon} />}
    </StyledButton>
  );
};

export default AntButton;
