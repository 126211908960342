export const GENERAL_CONFIG = [
  {
    label: "IDE",
    value: "IDE",
    order: 1,
    rbac: "MY_WORKSPACE",
  },
  {
    label: "Ingestion",
    value: "INGESTION",
    order: 2,
    rbac: "INGESTION",
  },
  {
    label: "Jobs",
    value: "JOBS",
    order: 3,
    rbac: "JOBS",
  },
  {
    label: "BI",
    value: "BUSINESS_INTELLIGENCE",
    order: 4,
    rbac: "BI",
  },
  {
    label: "Architecture",
    value: "ARCHITECTURE",
    order: 5,
    rbac: "ARCHITECTURE",
  },
  {
    label: "Utilization",
    value: "UTILIZATION",
    order: 6,
    rbac: "UTILIZATION",
  },
  {
    label: "Team",
    value: "TEAM",
    order: 7,
    rbac: "TEAMS",
  },
];
