import { createSlice } from "@reduxjs/toolkit";

export const ideSlice = createSlice({
  name: "IDE",
  initialState: {
    snowflakeRoles: [],
    snowflakeWarehouses: [],
    snowflakeDatabases: [],
    activeWorksheets: [],
    activeWorksheet: {},
    rawWorksheets: [],
    snowflakeColumns: [],
    heartbeatStatus: null,
    results: [],
    credentialData: {
      credentialData: "loading",
    },
    showFilter: [],
    selectedRole: null,
    selectedWarehouse: null,
    branchesData: {
      branchesData: [],
    },
    filesData: {
      filesData: [],
    },
    repositoriesData: {
      repositoriesData: [],
    },
    lineageModifiedFiles: {
      lineageModifiedFiles: [],
    },
    pythonPackages: {
      pythonPackages: {
        isPythonPackagesDisabled: false,
        isScriptExecutionDisabled: false,
      },
    },
    isPythonRefreshRequired: false,
    pythonRefreshStatus: null,
    pythonTerminalLogs: [],
    pythonTerminalInstallationLogs: {
      pythonTerminalInstallationLogs: [],
    },
    pythonTerminalInstallationRunning: false,
    pythonTerminalInstallationStartTime: null,
    pythonTerminalInstallationCommandId: null,
  },
  reducers: {
    setSnowflakeRoles: (state, action) => {
      if ("snowflakeRoles" in action.payload) {
        state.snowflakeRoles = action.payload;
      }
    },
    setSnowflakeWarehouses: (state, action) => {
      if ("snowflakeWarehouses" in action.payload) {
        state.snowflakeWarehouses = action.payload;
      }
    },
    setSnowflakeDatabases: (state, action) => {
      if ("snowflakeDatabases" in action.payload) {
        state.snowflakeDatabases = action.payload;
      }
    },
    setActiveWorksheets: (state, action) => {
      if ("activeWorksheets" in action.payload) {
        state.activeWorksheets = action.payload;
      } else if ("rawWorksheets" in action.payload) {
        state.rawWorksheets = action.payload;
      }
    },
    setActiveWorksheet: (state, action) => {
      if ("activeWorksheet" in action.payload) {
        state.activeWorksheet = action.payload;
      }
    },
    setSnowflakeColumns: (state, action) => {
      if ("snowflakeColumns" in action.payload) {
        state.snowflakeColumns = action.payload;
      }
    },
    setHeartbeatStatus: (state, action) => {
      if ("heartbeatStatus" in action.payload) {
        state.heartbeatStatus = action.payload;
      }
    },
    setResults: (state, action) => {
      if ("results" in action.payload) {
        state.results = action.payload;
      }
    },
    setCredentialsData: (state, action) => {
      if ("credentialData" in action.payload) {
        state.credentialData = action.payload;
      }
    },
    setShowFilter: (state, action) => {
      if ("showFilter" in action.payload) {
        state.showFilter = action.payload;
      }
    },
    setSelectedRole: (state, action) => {
      if ("selectedRole" in action.payload) {
        state.selectedRole = action.payload;
      }
    },

    setSelectedWarehouse: (state, action) => {
      if ("selectedWarehouse" in action.payload) {
        state.selectedWarehouse = action.payload;
      }
    },

    setBranchesDataRedux: (state, action) => {
      if ("branchesData" in action.payload) {
        state.branchesData = action.payload;
      }
    },

    setFilesData: (state, action) => {
      if ("filesData" in action.payload) {
        state.filesData = action.payload;
      }
    },

    setRepositoriesData: (state, action) => {
      if ("repositoriesData" in action.payload) {
        state.repositoriesData = action.payload;
      }
    },
    setLineageModifiedFiles: (state, action) => {
      if ("lineageModifiedFiles" in action.payload) {
        state.lineageModifiedFiles = action.payload;
      }
    },
    setUpdatePythonPackages: (state, action) => {
      if ("pythonPackages" in action.payload) {
        state.pythonPackages = action.payload;
      }
    },
    setIsPythonRefreshRequired: (state, action) => {
      if ("isPythonRefreshRequired" in action.payload) {
        state.isPythonRefreshRequired = action.payload;
      }
      if ("pythonRefreshStatus" in action.payload) {
        state.pythonRefreshStatus = action.payload;
      }
    },
    setPythonTerminalLogs: (state, action) => {
      if ("pythonTerminalLogs" in action.payload) {
        state.pythonTerminalLogs = action.payload;
      }
    },
    setPythonTerminalInstallationLogs: (state, action) => {
      if ("pythonTerminalInstallationLogs" in action.payload) {
        state.pythonTerminalInstallationLogs = action.payload;
      }
      if ("pythonTerminalInstallationRunning" in action.payload) {
        state.pythonTerminalInstallationRunning = action.payload;
      }
      if ("pythonTerminalInstallationStartTime" in action.payload) {
        state.pythonTerminalInstallationStartTime = action.payload;
      }
      if ("pythonTerminalInstallationCommandId" in action.payload) {
        state.pythonTerminalInstallationCommandId = action.payload;
      }
    },
  },
});

export const {
  setSnowflakeRoles,
  setSnowflakeWarehouses,
  setSnowflakeDatabases,
  setActiveWorksheets,
  setActiveWorksheet,
  setSnowflakeColumns,
  setHeartbeatStatus,
  setResults,
  setCredentialsData,
  setShowFilter,
  setSelectedRole,
  setSelectedWarehouse,
  setBranchesDataRedux,
  setFilesData,
  setRepositoriesData,
  setLineageModifiedFiles,
  setUpdatePythonPackages,
  setIsPythonRefreshRequired,
  setPythonTerminalLogs,
  setPythonTerminalInstallationLogs,
} = ideSlice.actions;

export default ideSlice.reducer;
