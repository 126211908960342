// A custom fetcher that performs a POST request
// const baseURL = "https://apinextgen-dev.5x.co";

import { logout } from "../../helpers/authentication";
import { getAccessTokenFromCookie } from "../../helpers/cookieStorage";

// A custom fetcher that performs a POST request
export const postFetcher = async (
  url: string,
  data: any | null,
): Promise<any> => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessTokenFromCookie()}`,
    },
    body: JSON.stringify(data),
  });
  if (!response?.ok && response?.status === 401) {
    return logout();
  }
  return response.json();
};

export const postFileFetcher = async (
  url: string,
  data: any | null,
): Promise<any> => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}${url}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getAccessTokenFromCookie()}`,
    },
    body: data,
  });
  if (!response?.ok && response?.status === 401) {
    return logout();
  }
  return response.json();
};

export const postFetcherNoAuth = async (
  url: string,
  data: any,
): Promise<any> => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  if (!response?.ok && response?.status === 401) {
    return logout();
  }
  return response.json();
};

export const patchFetcher = async (url: string, data: any): Promise<any> => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}${url}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessTokenFromCookie()}`,
    },
    body: JSON.stringify(data),
  });
  if (!response?.ok && response?.status === 401) {
    return logout();
  }
  return response.json();
};

export const getFetcher = async (url: string): Promise<any> => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}${url}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessTokenFromCookie()}`,
    },
  });
  if (!response?.ok && response?.status === 401) {
    return logout();
  }
  return response?.json();
};

export const getFetcherNoAuth = async (url: string): Promise<any> => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}${url}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response?.ok && response?.status === 401) {
    return logout();
  }
  return response?.json();
};

export const imagesFetcher = async (url: string): Promise<any> => {
  try {
    const res = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}${url}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessTokenFromCookie()}`,
      },
    });
    return res.text();
  } catch (error) {
    return error;
  }
};

export const deleteFetcher = async (url: string): Promise<any> => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}${url}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessTokenFromCookie()}`,
    },
  });
  if (!response?.ok && response?.status === 401) {
    return logout();
  }
  return response.json();
};

export const deleteFetcherByKey = async (
  url: string,
  key: string,
): Promise<any> => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_BASE_URL}${url}/${key}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessTokenFromCookie()}`,
      },
    },
  );
  if (!response?.ok && response?.status === 401) {
    return logout();
  }
  return response.json();
};

export const postFetcherHubspot = async (
  url: string,
  data: any | null,
): Promise<any> => {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.NEXT_PUBLIC_HUBSPOT_API_KEY}`,
    },
    body: JSON.stringify(data),
  });
  return response.json();
};
