import { navigationIcons } from "../icons/navigations";
export const settingsMenu = [
  {
    label: "General",
    key: 11,
    icon: navigationIcons.general,
    activeIcon: navigationIcons.generalActive,
    path: "/settings/general",
    id: "settings_general",
  },
  {
    label: "App connections",
    key: 1,
    icon: navigationIcons.appConnection,
    activeIcon: navigationIcons.appConnectionActive,
    path: "/settings/connections",
    id: "settings_app_connections",
  },
  {
    label: "Credentials",
    key: 2,
    icon: navigationIcons.credential,
    activeIcon: navigationIcons.credentialActive,
    path: "/settings/credentials",
    id: "settings_credentials",
  },
  {
    label: "Environment",
    key: 3,
    icon: navigationIcons.environment,
    activeIcon: navigationIcons.environmentActive,
    path: "/settings/environment",
  },
  {
    label: "Docs",
    key: 4,
    icon: navigationIcons.dbtDocs,
    activeIcon: navigationIcons.dbtDocsActive,
    path: "/settings/docs",
  },
  // {
  //   label: "API key",
  //   key: 4,
  //   icon: navigationIcons.key,
  //   activeIcon: navigationIcons.keyActive,
  //   path: "/settings/api-key",
  //   id: "settings_api_key",
  // },
  {
    label: "Alerts",
    key: 7,
    icon: navigationIcons.alertsBlack,
    activeIcon: navigationIcons.alertsActive,
    path: "/settings/alerts",
    id: "settings_alerts",
  },
  {
    label: "Billing",
    key: 8,
    icon: navigationIcons.billing,
    activeIcon: navigationIcons.billingActive,
    path: "/settings/billing",
    id: "settings_billing",
  },
  {
    label: "Profile",
    key: 9,
    icon: navigationIcons.profile,
    activeIcon: navigationIcons.profileActive,
    path: "/settings/profile",
    id: "settings_profile",
  },
  {
    label: "Security",
    key: 12,
    icon: navigationIcons.securityIcon,
    activeIcon: navigationIcons.securityIconActive,
    path: "/settings/security",
    id: "settings_security",
  },
  {
    label: "Log out",
    key: 11,
    icon: navigationIcons.logout,
    activeIcon: navigationIcons.logout,
    path: "/settings/logout",
    color: "red",
  },
];
